<template>
    <el-main>
        <!-- 搜索 -->
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="订单编号：">
                <el-input size="small" v-model="form.order_no" placeholder="请输入订单编号"></el-input>
            </el-form-item>
            <el-form-item label="用户昵称：">
                <el-input size="small" v-model="form.nickname" placeholder="请输入用户昵称"></el-input>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker v-model="form.time" size="small" type="daterange" value-format="timestamp"
                    range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="跳转类型：">
                <el-select v-model="form.goto_type" placeholder="请选择跳转类型" size="small" filterable clearable>
                    <el-option v-for="item in goto_type" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="关键词标题：">
                <el-input size="small" v-model="form.title" placeholder="请输入关键词标题"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
                <el-button type="primary" size="small" @click="search">搜索</el-button>
                <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <!-- 选项卡 -->
        <el-tabs type="card" v-model="form.status" @tab-click="tabClick">
            <el-tab-pane :name="item.status" v-for="item in status" :key="item.status"
                :label="item.title + '（' + item.count + '）'"></el-tab-pane>
        </el-tabs>
        <!-- 表格 -->
        <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" v-if="status.length > 0">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="order_no" label="订单编号" align="center" width="185"></el-table-column>
            <el-table-column label="用户信息" align="center" width="160">
                <template slot-scope="scope">
                    <div class="user">
                        <el-image :src="scope.row.avatar" alt=""></el-image>
                        <div class="goods_name">{{ scope.row.nickname }}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="mobile" label="会员手机号" align="center" width="110"></el-table-column>
            <el-table-column prop="title" label="关键词标题" align="center" width="110"></el-table-column>
            <el-table-column prop="bid_amount" label="竞价金额" align="center">
                <template slot-scope="scope">
                    ￥{{ scope.row.bid_amount }}
                </template>
            </el-table-column>
            <el-table-column prop="goto_type" label="跳转类型" align="center">
                <template slot-scope="scope">
                    {{ goto_type.find(item => item.value == scope.row.goto_type).label }}
                </template>
            </el-table-column>
            <el-table-column prop="day" label="申请天数" align="center"></el-table-column>
            <el-table-column prop="discount" label="会员折扣" align="center"></el-table-column>
            <el-table-column prop="discount_price" label="折扣金额" align="center">
                <template slot-scope="scope">
                    ￥{{ scope.row.discount_price }}
                </template>
            </el-table-column>
            <el-table-column prop="amount" label="支付金额" align="center">
                <template slot-scope="scope">
                    ￥{{ scope.row.amount }}
                </template>
            </el-table-column>
            <el-table-column prop="pay_integral" label="支付积分" align="center"></el-table-column>
            <el-table-column prop="create_time" width="160" label="创建时间" align="center">
                <template slot-scope="scope">
                    <span>{{ getDateformat(scope.row.create_time) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="关键词状态" align="center" width="110">
                <template slot-scope="scope">
                    <span
                        :style="{ 'color': scope.row.status == 1 ? '#E6A23C' : (scope.row.status == -1 || scope.row.status == 2) ? '#409EFF' : '#F56C6C' }">
                        {{ status.find(item => item.status == scope.row.status).title }}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="160" align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="getInfo(scope.row.id)">查看申请</el-button>
                    <el-button type="text" size="small" @click="$router.push('/extension/keywords/list')"
                        v-if="scope.row.status == 2">查看列表</el-button>
                    <el-button type="text" size="small" @click="audit(scope.row.id, '2')"
                        v-if="scope.row.status == 1">通过</el-button>
                    <el-button type="text" size="small" @click="audit(scope.row.id, '3')"
                        v-if="scope.row.status == 1">驳回</el-button>
                    <el-button type="text" size="small" @click="seeReason(scope.row.id)"
                        v-if="scope.row.status == 3">驳回原因</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="total_number" :page="form.page" :pageNum="form.rows" @updatePageNum="updateData"></Paging>
        <!-- 查看申请 -->
        <el-dialog title="查看申请" :visible.sync="dialogVisible_detail" width="40%">
            <div class="dialog_main">
                <div class="rows">关键词标题：{{ order_info.title }}</div>
                <div class="rows">竞价金额：￥{{ order_info.bid_amount }}</div>
                <div class="rows">申请天数：{{ order_info.day }}</div>
                <div>
                    <div v-if="order_info.goto_type">
                        跳转路径：{{ goto_type.find(item => item.value == order_info.goto_type).label }}
                    </div>
                    <div class="user goods">
                        <el-image :src="order_info.goto_picture" alt=""></el-image>
                        <div class="goods_name">{{ order_info.goto_name }}</div>
                    </div>
                </div>
                <div class="rows price">
                    <div>关键词价格：￥{{ order_info.total_price }}</div>
                    <div>支付价格：￥{{ order_info.amount }}</div>
                    <div>积分：{{ order_info.pay_integral }}</div>
                </div>
            </div>
        </el-dialog>
        <!-- 审核 -->
        <el-dialog title="审核" :visible.sync="dialogVisible_audit" width="30%" :before-close="clearAuditForm">
            <el-form class="dialog_main" label-position="top" :model="audit_form" ref="audit_form" :rules="rules">
                <el-form-item label="请选择审核结果：" prop="status">
                    <el-radio-group v-model="audit_form.status">
                        <el-radio label="2">通过</el-radio>
                        <el-radio label="3">驳回</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="请添加驳回原因：" v-if="audit_form.status == 3" prop="remark">
                    <el-input type="textarea" :rows="4" placeholder="请输入驳回原因" v-model="audit_form.remark"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_audit = false">取 消</el-button>
                <el-button type="primary" @click="confirmAudit">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 驳回原因 -->
        <el-dialog title="审核" :visible.sync="dialogVisible_reason" width="30%">
            <div class="dialog_main">
                {{ reason }}
            </div>
        </el-dialog>
    </el-main>
</template>
<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import { goto_type } from './data'
export default {
    components: {
        Paging,
    },
    data () {
        return {
            form: {
                page: 1,
                rows: 10,
                order_no: '',
                nickname: '',
                time: '',
                goto_type: '',
                title: '',
                status: '0'
            },
            goto_type: goto_type,
            status: [],
            list: [],
            total_number: 0,

            dialogVisible_detail: false,
            order_info: {},
            dialogVisible_audit: false,
            audit_form: {
                id: '',
                status: '2',
                remark: ''
            },
            dialogVisible_reason: false,
            rules: {
                remark: [{ required: true, message: '请输入驳回原因', trigger: 'blur' },]
            },
            reason: '666'
        };
    },
    created () {
        this.getStatus()
        this.getList();
    },
    methods: {
        tabClick () {
            this.form.page = 1;
            this.getList();
        },
        search () {
            this.form.page = 1;
            this.getStatus()
            this.getList();
        },
        cancelSearch () {
            this.form = {
                page: 1,
                rows: 10,
                order_no: '',
                nickname: '',
                time: '',
                goto_type: '',
                title: '',
                status: '0'
            };
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.form.rows = val;
            } else {
                this.form.page = val;
            }
            this.getList();
        },
        getStatus () {
            this.$axios.post(this.$api.gateway.keywords.orderStatus, {
                order_no: this.form.order_no,
                nickname: this.form.nickname,
                start_time: this.form.time ? this.form.time[0] / 1000 : '',
                end_time: this.form.time ? this.form.time[1] / 1000 : '',
                goto_type: this.form.goto_type,
                title: this.form.title,
            })
                .then(res => {
                    if (res.code == 0) {
                        this.status = res.result
                    }
                });
        },
        getList () {
            let data = Object.assign({}, this.form)
            if (data.time) {
                data.start_time = data.time[0] / 1000
                data.end_time = data.time[1] / 1000
            }
            delete data.time
            this.$axios.post(this.$api.gateway.keywords.orderList, data)
                .then(res => {
                    if (res.code == 0) {
                        this.list = res.result.list;
                        this.total_number = res.result.total_number;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getInfo (id) {
            this.$axios.post(this.$api.gateway.keywords.orderInfo, { id })
                .then(res => {
                    if (res.code == 0) {
                        this.order_info = res.result
                        this.dialogVisible_detail = true
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        audit (id, status) {
            this.audit_form.id = id
            this.audit_form.status = status
            this.dialogVisible_audit = true
        },
        confirmAudit () {
            this.$refs.audit_form.validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.gateway.keywords.audit, this.audit_form)
                        .then(res => {
                            if (res.code == 0) {
                                this.dialogVisible_audit = false
                                this.$message.success('提交成功');
                                this.getList()
                            } else {
                                this.$message.error(res.msg);
                            }
                        });
                }
            });
        },
        clearAuditForm (done) {
            this.audit_form.remark = ''
            done()
        },
        seeReason (id) {
            this.$axios.post(this.$api.gateway.keywords.orderInfo, { id })
                .then(res => {
                    if (res.code == 0) {
                        this.reason = res.result.remark
                        this.dialogVisible_reason = true
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        }
    },
};
</script>
<style lang="less" scoped>
.el-main {
    background: #fff;

    .rows {
        display: flex;
        align-items: center;
        margin: 30px 0;
    }

    .dialog_main {
        min-height: 200px;
    }
}

.user {
    display: flex;
    align-items: center;

    .el-image {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
    }

    .goods_name {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        flex: 1;
        text-align: left;
    }
}

.goods {
    width: 80%;
    align-items: flex-start;
    margin-left: 70px;
    margin-top: 20px;

    .el-image {
        width: 60px;
        height: 60px;
        border-radius: 0;
    }
}

.price {
    div+div {
        margin-left: 40px;
    }
}</style>
